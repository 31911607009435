import React from 'react';
import HomemadeFood from '../../Images/HomemadeFood.png';

import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import './WhyUs.css';

const WhyUs = () => {
  const whyUSData = [
    {
      title: 'Safe',
      msg: `From buying ingredients to cooking, from cooking to packing, and from packing to delivery, all the safety measures are followed strictly!`,
    },
    {
      title: 'Fresh',
      msg: `No bulk cooking! We cook depending on the number of orders we receive, That is why prior order is required, This makes sure that you receive hot and fresh food always.`,
    },
    {
      title: 'Healthy',
      msg: `Our main goal is to provide healthy food to everyone. No harmful materials are used in any of our meals because health matters the most!`,
    },
  ];
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isxs = useMediaQuery(theme.breakpoints.only('xs'));

  const whyUsStyles = {
    circles: {
      height: isxs ? '20rem' : isMobile ? '25rem' : isTab ? '28rem' : '',
      width: isxs ? '20rem' : isMobile ? '25rem' : isTab ? '28rem' : '',
    },
    circleP: {
      width: isxs ? '19rem' : isMobile ? '24.5rem' : isTab ? '27.5rem' : '',
      fontSize: isxs ? '1rem' : isMobile ? '1.1rem' : isTab ? '1.3rem' : '',
    },
    circlesImg: {
      width: isxs ? '100%' : isMobile ? '100%' : isTab ? '90%' : '',

      objectFit: isxs ? '' : isMobile ? '%' : isTab ? '' : '',
    },
  };
  return (
    <div className='mainContainer'>
      <div id='whyUs'>
        <h4>Why Us</h4>
      </div>

      <div className='whyUsContent'>
        <div className='whyUsContentCover'>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={{ xs: 2, md: 5, lg: 10 }}
          >
            {whyUSData.map((whysUsdata, Index) => {
              return (
                <Grid item key={Index}>
                  <div className='circles' style={whyUsStyles.circles}>
                    <h4>{whysUsdata.title}</h4>
                    <p style={whyUsStyles.circleP}>{whysUsdata.msg}</p>
                  </div>
                </Grid>
              );
            })}{' '}
          </Grid>
        </div>

        <div className={'bottomImg'}>
          <img
            src={HomemadeFood}
            alt='Home Made Food'
            style={whyUsStyles.circlesImg}
          />
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
