import React from 'react';
import './App.css';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer.jsx';
import { Home, Menu, Tiffin, About, ContactUs, ErrorPage } from './Pages';

const App = () => {
  const location = useLocation();
  return (
    <main>
      <div className='App'>
        <div className='Header'>
          <Header />
        </div>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.key}>
            <Route exact path={['/', '/home']}>
              <div className='Home'>
                <Home />
              </div>
            </Route>
            <Route path='/menu'>
              <div className='Menu'>
                <Menu />
              </div>
            </Route>
            <Route path='/tiffin'>
              <div className='Tiffin'>
                <Tiffin />
              </div>
            </Route>
            <Route path='/about'>
              <div className='About'>
                <About />
              </div>
            </Route>
            <Route path='/contactUs'>
              <div className='ContactUs'>
                <ContactUs />
              </div>
            </Route>
            <Route>
              <div className='ErrorPage'>
                <ErrorPage />
              </div>
            </Route>
          </Switch>
        </AnimatePresence>
        <div className='Footer'>
          <Footer />
        </div>
      </div>
    </main>
  );
};

export default App;
