import React from 'react';
import { Link } from 'react-router-dom';
import OurMealPlans from '../../Images/OurMealPlans.png';
import VegTiffin from '../../Images/VegTiffin.png';
import NonVegTiffin from '../../Images/NonVegTiffin.png';

import './MenuBanner.css';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

const MenuBanner = () => {
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isxs = useMediaQuery(theme.breakpoints.only('xs'));
  const menubannerStyles = {
    OurmealImgDiv: {
      width: isxs ? '95%' : isMobile ? '80%' : isTab ? '85%' : '',
    },
    tiffinImageContainer: {
      flexDirection: isMobile && 'column',
    },
    menuImg: {
      width: isxs ? '95%' : isMobile ? '80%' : isTab ? '85%' : '',
      margin: isMobile && '1rem',
    },
  };
  return (
    <div className='Menucontainer'>
      <div className='Menucontent'>
        <div className='OurmealImgDiv'>
          <img
            src={OurMealPlans}
            alt='MealPlans'
            id='OurmealPlanImg'
            draggable={false}
            style={menubannerStyles.OurmealImgDiv}
          />

          <div className='MenuTimetext'>
            <h1
              style={
                isxs
                  ? { fontSize: '1.2rem' }
                  : isTab
                  ? { fontSize: '1.5rem' }
                  : {}
              }
            >
              BREAKFAST - LUNCH - DINNER
            </h1>
          </div>
        </div>
        <div
          className='tiffinImageContainer'
          style={menubannerStyles.tiffinImageContainer}
        >
          <div>
            <img
              src={VegTiffin}
              alt='VegTiffin'
              className='menuImg'
              style={menubannerStyles.menuImg}
            />
          </div>

          <div>
            <img
              src={NonVegTiffin}
              alt='NonVegTiffin'
              className='menuImg'
              style={menubannerStyles.menuImg}
            />
          </div>
        </div>
        <div className='menuBtn'>
          <div>
            <Link to='menu' style={{ textDecoration: 'none' }}>
              <Button
                variant='contained'
                color='success'
                sx={{
                  fontSize: '1.2rem',
                  margin: '1rem',
                  width: '15rem',
                }}
              >
                Menu
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBanner;
