import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import HomeIcon from '@mui/icons-material/Home';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LoginIcon from '@mui/icons-material/Login';

import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';

const Footer = () => {
  const mNavLinks = [
    {
      mlinkIcon: <HomeIcon color='success' />,
      mlinktext: 'Home',
    },
    {
      mlinkIcon: <RestaurantMenuIcon color='success' />,
      mlinktext: 'Menu',
    },
    {
      mlinkIcon: <LunchDiningIcon color='success' />,
      mlinktext: 'Tiffin',
    },
    {
      mlinkIcon: <InfoIcon color='success' />,
      mlinktext: 'About',
    },
  ];

  const theme = useTheme();
  //const isTab = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isxs = useMediaQuery(theme.breakpoints.only('xs'));

  const NavStyle = {
    mLink: {
      textDecoration: 'none',
      color: 'white',
    },
    preFooter: {
      flexDirection: isMobile && 'column',
      alignItems: isMobile && 'flex-start',
    },
    brand: {
      justifyContent: isxs && 'center',
      height: isxs && '12vh',
    },
    mBrandviewh: {
      fontSize: isxs && '.9rem',
    },
  };

  const mobileMenu = mNavLinks.map((mNavLinks, index) => {
    const { mlinkIcon, mlinktext } = mNavLinks;

    return (
      <Link
        to={
          mlinktext === 'Login/Signup'
            ? '/loginsignup'
            : mlinktext === 'Contact Us'
            ? 'contactus'
            : mlinktext.toLowerCase()
        }
        key={index}
        style={NavStyle.mLink}
      >
        <ListItem button divider style={{ color: 'white' }}>
          <ListItemIcon>{mlinkIcon}</ListItemIcon>
          <ListItemText primary={mlinktext} />
        </ListItem>
      </Link>
    );
  });

  return (
    <footer>
      <div className='footerSection' style={NavStyle.preFooter}>
        {isMobile ? (
          ''
        ) : (
          <div className='footerNav'>
            <List>{mobileMenu}</List>
          </div>
        )}
        <div className='contactUs'>
          <div>
            <ListItem>
              <ListItemIcon>
                <ContactMailIcon fontSize='large' color='primary' />
              </ListItemIcon>
            </ListItem>
            <hr style={{ width: '80%' }} />
            <hr style={{ width: '40%', backgroundColor: 'black' }} />
          </div>
          <div>
            <ListItem>
              <label htmlFor=''>Call : &nbsp; </label>
              <label htmlFor=''>
                <a href='tel:9833501408'> 9833501408 </a>{' '}
              </label>
            </ListItem>
            <ListItem>
              <label htmlFor=''>Whatsapp : &nbsp; </label>
              <label htmlFor=''>
                <a href='https://wa.me/919833501408?text=Hi%20Tiffin%20For%20Me%20Today!'>
                  {' '}
                  9833501408{' '}
                </a>{' '}
              </label>
            </ListItem>

            <ListItem>
              <label htmlFor=''>
                <a href='mailto:reshmaskhan00@gmail.com'>
                  reshmaskhan00@gmail.com
                </a>
              </label>
            </ListItem>
          </div>
        </div>
        {isMobile && <hr style={{ width: '80%' }} />}
        <div className='logSign'>
          <div>
            <ListItem>
              <ListItemIcon>
                <LoginIcon fontSize='large' color='primary' />
              </ListItemIcon>
            </ListItem>
            <hr style={{ width: '80%' }} />
            <hr style={{ width: '40%', backgroundColor: 'black' }} />
          </div>
          <div>
            <Link to='/loginsignup' style={NavStyle.mLink}>
              <ListItem button divider>
                <ListItemText primary='Login' />
              </ListItem>
            </Link>
            <Link to='/loginsignup' style={NavStyle.mLink}>
              <ListItem button divider>
                <ListItemText primary='Sign Up' />
              </ListItem>
            </Link>
          </div>
        </div>
      </div>
      <div className='branding' style={NavStyle.brand}>
        {isxs ? (
          <div className='mBrandview'>
            <h5 style={NavStyle.mBrandviewh}>
              &#169; 2022 Reshma Tiffin Service
            </h5>
            <h6>
              Powered By <a href='https://cyntaxerror.com/'>Cyntax Error</a>
            </h6>
          </div>
        ) : (
          <>
            <div className='copyRight'>
              <p>&#169; 2022 Reshma Tiffin Service</p>
            </div>
            <div className='companyText'>
              Powered By <a href='https://cyntaxerror.com/'>Cyntax Error</a>
            </div>
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
