import React from 'react';
import ComingSoon from '../Components/ComingSoon';

const About = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default About;
