import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import Logo from '../../Images/Logo.png';

import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LoginIcon from '@mui/icons-material/Login';

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

import { useMediaQuery, useTheme } from '@mui/material';

const Header = () => {
  const navLinks = ['Home', 'Menu', 'Tiffin', 'About', 'Contact Us'];
  const mNavLinks = [
    {
      mlinkIcon: <HomeIcon color='success' />,
      mlinktext: 'Home',
    },
    {
      mlinkIcon: <RestaurantMenuIcon color='success' />,
      mlinktext: 'Menu',
    },
    {
      mlinkIcon: <LunchDiningIcon color='success' />,
      mlinktext: 'Tiffin',
    },
    {
      mlinkIcon: <InfoIcon color='success' />,
      mlinktext: 'About',
    },
    {
      mlinkIcon: <ContactMailIcon color='success' />,
      mlinktext: 'Contact Us',
    },
    {
      mlinkIcon: <LoginIcon color='success' />,
      mlinktext: 'Login/Signup',
    },
  ];

  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isxs = useMediaQuery(theme.breakpoints.only('xs'));

  const NavStyle = {
    logoStyle: {
      height: isxs ? '4rem' : isMobile ? '5rem' : isTab ? '5.8rem' : '',
      width: isxs ? '4rem' : isMobile ? '5rem' : isTab ? '5.8rem' : '',
      marginLeft: isxs ? '.5rem' : isMobile ? '1rem' : isTab ? '2.3rem' : '',
    },
    mLink: {
      textDecoration: 'none',
      color: 'black',
    },
    logotextH1: {
      fontSize: isxs ? '1.2rem' : isMobile ? '1.4rem' : isTab ? '2rem' : '',
    },
    logotextH4: {
      fontSize: isxs ? '.9rem' : isMobile ? '1rem' : isTab ? '1.1rem' : '',
    },
  };
  const [anchorEl, setAnchorEl] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setAnchorEl({ anchorEl, [anchor]: open });
  };

  const mobileMenu = mNavLinks.map((mNavLinks, index) => {
    const { mlinkIcon, mlinktext } = mNavLinks;

    return (
      <Link
        to={
          mlinktext === 'Login/Signup'
            ? '/loginsignup'
            : mlinktext === 'Contact Us'
            ? 'contactus'
            : mlinktext.toLowerCase()
        }
        key={index}
        style={NavStyle.mLink}
      >
        <ListItem button divider sx={{ height: 70 }}>
          <ListItemIcon>{mlinkIcon}</ListItemIcon>
          <ListItemText primary={mlinktext} />
        </ListItem>
      </Link>
    );
  });

  const deskMenu = navLinks.map((navlinks, index) => {
    return (
      <li key={index}>
        <Link
          to={
            navLinks[index] === 'Contact Us'
              ? 'contactus'
              : navLinks[index].trim('').toLowerCase()
          }
        >
          {navlinks}
        </Link>
      </li>
    );
  });
  return (
    <header>
      <nav>
        <div className='logoCon'>
          <img
            src={Logo}
            draggable='false'
            alt='Reshma Tiffin Service Logo'
            id='logo'
            style={NavStyle.logoStyle}
          />
        </div>
        <div className='LogoText'>
          <h1 style={NavStyle.logotextH1}>Reshma Tiffin Service</h1>
          <h4 style={NavStyle.logotextH4}>Health Matters The Most!</h4>
        </div>
      </nav>
      <div className='NavLinks'>
        {isTab ? (
          <>
            <MenuIcon
              fontSize='large'
              onClick={toggleDrawer('right', true)}
              style={{ cursor: 'pointer' }}
              color='success'
            />
            <Drawer
              anchor={'right'}
              open={anchorEl['right']}
              onClose={toggleDrawer('right', false)}
            >
              <Box
                sx={{ width: 250 }}
                role='presentation'
                onClick={toggleDrawer('right', false)}
                onKeyDown={toggleDrawer('right', false)}
              >
                <List>{mobileMenu}</List>
              </Box>
            </Drawer>
          </>
        ) : (
          <>
            <ul>{deskMenu}</ul>
          </>
        )}
        <div style={isTab ? { display: 'none' } : {}}>
          <a href='https://wa.me/919833501408?text=Hi%20Tiffin%20For%20Me%20Today!'>
            <input type='button' value='Click To Whatsapp' id='lsButton' />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
