import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import MenuItems from '../Components/Menu/MenuItems';
import {
  AlooParatha,
  BhurjiPav,
  ChickenBiryani,
  NonVegTiffin,
  PavBhaji,
  VegBiryani,
  VegTiffin,
  PuriBhaji,
} from '../Components/Menu/Images';
const Menu = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const foodMenu = [
    {
      id: 0,
      name: 'Puri Bhaji',
      sub: 'Fresh - Healthy - Delicious',
      image: PuriBhaji,
      category: 'Breakfast or Lunch',
      discription: `Puri Bhaji! Fresh Hot & Healthy! for Everyone Starting @80Rs Exclusive Of Delivery Charges.
      Includes 1 Bhaji (Gravy Or Dry) & 3 Puri. Must Order 3Hrs Before.`,
    },
    {
      id: 1,
      name: 'Aaloo Paratha',
      sub: 'Best Breakfast Meal',
      image: AlooParatha,
      category: 'Breakfast',
      discription: `Aaloo Paratha For a Heavy Breakfast, Enjoy With Home Made Green Chutney 
      @40Rs Each,
      Orders Accepted Only If Quantity Is More Then 3 
      Must Order 3Hrs Before.`,
    },
    {
      id: 2,
      name: 'Bhurji Pav',
      sub: 'Perfect Breakfast Partner',
      image: BhurjiPav,
      category: 'Breakfast',
      discription: `Best Breakfast Meal For Everyone In The World, With Lots Of Protein & Vitamins 
      This Meal Will Keep You, Full, Till Lunch Time, Bhurji Pav Starting @50Rs,
      Includes 1 Bhurji 2 Pav.`,
    },
    {
      id: 3,
      name: 'Pav Bhaji',
      sub: 'All Rounder',
      image: PavBhaji,
      category: 'Breakfast',
      discription: `Fresh - Healthy & Delicious!!
      A Mixture of Lot Different Vegetables With Butter & Coriander On Top,
      Starting @100Rs,
      Includes 1 Bhaji & 2 Pav.
      Must Order 3Hrs Before.`,
    },
    {
      id: 4,
      name: 'Veg Biriyani',
      sub: 'Lunch - Dinner - Party',
      image: VegBiryani,
      category: 'Lunch|Dinner|Party',
      discription: `Its a Party Or a Family Gathering, Or You Just Got Bored Eating The Same Normal 
      Food, We Got You Covered,
      Enjoy Our Home Made Veg Biryani With No Harmful Food Colouring.
      Starting @800Rs Kg.
      Please Order a Day Before.
      `,
    },
    {
      id: 5,
      name: 'Chicken Biryani',
      sub: 'Lunch - Dinner - Party',
      image: ChickenBiryani,
      category: 'Breakfast',
      discription: `Its a Party Or a Family Gathering, Or You Just Got Bored Eating The Same Normal 
      Food, We Got You Covered,
      Enjoy Our Home Made Chicken Biryani With No Harmful Food Colouring.
      Starting @1300Rs Kg.`,
    },
    {
      id: 6,
      name: 'Non-Veg Tiffin',
      sub: 'Fresh - Healthy - Delicious',
      image: NonVegTiffin,
      category: 'Breakfast',
      discription: `Now Its Time For You'll To Give Up All That Unhealthy, Junky Outside Food,
      With Our Home Made Tiffin You'll Will Never Feel Away From Home,
      Eat Good & Stay Healthy!
      Non-Veg Tiffin Starting @180Rs.
      `,
    },
    {
      id: 7,
      name: 'VegTiffin',
      sub: 'Fresh - Healthy - Delicious',
      image: VegTiffin,
      category: 'Breakfast',
      discription: `Now Its Time For You'll To Give Up All That Unhealthy, Junky Outside Food,
      With Our Home Made Tiffin You'll Will Never Feel Away From Home,
      Eat Good & Stay Healthy!
      Veg Tiffin Starting @130Rs.`,
    },
  ];
  return (
    <motion.div
      initial={{ Opacity: 0, x: '-100vw' }}
      animate={{ Opacity: 1, x: '0' }}
      exit={{ Opacity: 0, x: '-100vw' }}
      transition={{ type: 'spring', duration: 0.5 }}
      className='menuContainer'
    >
      <MenuItems foodMenu={foodMenu} />
    </motion.div>
  );
};

export default Menu;
