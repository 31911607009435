import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import './MenuItems.css';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Zoom from '@mui/material/Zoom';
import Button from '@mui/material/Button';

const MenuItems = (props) => {
  const [checked] = useState(true);
  return (
    <div className='MenuItems'>
      <Grid
        container
        spacing={1}
        direction='row'
        alignItems='center'
        justifyContent='center'
      >
        <Grid item xs={12}>
          <div className='items'>
            <Grid
              container
              justifyContent='center'
              spacing={3}
              alignItems='center'
            >
              {/* .sort((a, b) => a.name.localeCompare(b.name)) Code To Sort*/}
              {props.foodMenu.map((items) => {
                return (
                  <Grid item key={items.id}>
                    <Zoom in={checked} timeout={1000}>
                      <Card sx={{ maxWidth: 345 }}>
                        <CardHeader
                          avatar={
                            <Avatar
                              aria-label={items.name}
                              sx={{ bgcolor: 'lightgreen' }}
                            >
                              {items.name.charAt(0)}
                            </Avatar>
                          }
                          title={items.name}
                          subheader={items.sub}
                          sx={{ bgcolor: 'lightgray' }}
                        />
                        <CardMedia
                          component='img'
                          image={items.image}
                          title={items.name}
                          height='194'
                          alt={items.name}
                        />
                        <CardContent>
                          <Typography
                            variant='body2'
                            color='textSecondary'
                            component='p'
                          >
                            {items.discription}
                          </Typography>
                        </CardContent>
                        <CardActions disableSpacing sx={{ float: 'right' }}>
                          <a
                            href={`https://wa.me/919833501408?text=Hi%20${items.name}%20For%20Me%20Today!`}
                          >
                            <Button size='medium' variant='outlined'>
                              Order Now
                            </Button>
                          </a>
                        </CardActions>
                      </Card>
                    </Zoom>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
        <Grid item style={{ marginTop: '1rem' }}>
          <h1>Want More Options?</h1>
        </Grid>
        <Grid item style={{ marginTop: '1rem' }}>
          <a href='tel:9833501408'>
            <Button variant='outlined' size='large'>
              Call Us
            </Button>
          </a>
        </Grid>
      </Grid>
    </div>
  );
};
export default MenuItems;
