import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Css/Home.css';
import { HeroBanner, WhyUs, MenuBanner } from '../Components/Home';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <AnimatePresence initial={true}>
      <motion.div
        className='homContainer'
        initial={{ Opacity: 0, x: '-100vw' }}
        animate={{ Opacity: 1, x: '0' }}
        exit={{ Opacity: 0, x: '-100vw' }}
        transition={{ type: 'spring', duration: 0.5 }}
      >
        <div className='heroBanner'>
          <HeroBanner />
        </div>
        <div className='whyUs'>
          <WhyUs />
        </div>
        <div className='menuBanner'>
          <MenuBanner />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Home;
