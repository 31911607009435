import React from 'react';

import './HeroBanner.css';

import { HomePageLanding, Banner, OrderNowBtn } from '../../Images';

import { motion } from 'framer-motion';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const HeroBanner = () => {
  //const history = useHistory();
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isxs = useMediaQuery(theme.breakpoints.only('xs'));

  const heroStyles = {
    hBGImage: {
      height: isMobile && '85vh',
    },
    bannerImg: {
      width: isxs ? '100%' : isMobile ? '100%' : isTab ? '90%' : '',
    },
    bannerTextCon: {
      margin: isxs ? '2rem 0rem' : isTab ? '1.5rem 1rem' : '',
      top: isTab && '60vh',
    },
    bannerText: {
      fontSize: isxs ? '1.3rem' : isMobile ? '2.5rem' : isTab ? '2.9rem' : '',
    },
    orderImg: {
      width: isxs && '55%',
    },
  };
  return (
    <div className='HeroBanner'>
      <img
        src={HomePageLanding}
        alt='Tiffin Home Page Banner'
        id='HomePageBG'
        draggable={false}
        style={heroStyles.hBGImage}
      />
      <div className='BannerCon'>
        <img
          src={Banner}
          draggable='false'
          alt='Tiffin Promo'
          id='Banner'
          style={heroStyles.bannerImg}
        />
      </div>
      <div className='bannerTextCon'>
        <div className='bannerText' style={heroStyles.bannerTextCon}>
          {isxs ? (
            <motion.h4
              initial={{ opacity: 0, y: '-20vh' }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: 'spring', duration: 0.5, delay: 0.2 }}
              style={heroStyles.bannerText}
            >
              BREAKFAST - LUNCH & DINNER
            </motion.h4>
          ) : (
            <motion.h4
              initial={{ opacity: 0, y: '-20vh' }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: 'spring', duration: 0.1, delay: 0.2 }}
              style={heroStyles.bannerText}
            >
              BREAKFAST - LUNCH - DINNER
            </motion.h4>
          )}
          <a
            href='https://wa.me/919833501408?text=Hi%20Tiffin%20For%20Me%20Today!'
            style={{ textAlign: 'center' }}
          >
            <motion.img
              src={OrderNowBtn}
              alt='OrderNow Button'
              className='orderNowBtn'
              draggable='false'
              initial={{ opacity: 0, x: '70vw' }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                type: 'spring',
                duration: 0.5,
                delay: 0.5,
                stiffness: 50,
              }}
              style={heroStyles.orderImg}
              //onClick={() => history.push('/menu')}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
