import React from 'react';
import ComingSoon from '../Components/ComingSoon';

const ContactUs = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default ContactUs;
