import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className='csComtainer'>
      <h1>Page Under Construction</h1>
      <h4>
        For Details Give Us A <a href='tel:9833501408'>Call</a>
      </h4>
    </div>
  );
};

export default ComingSoon;
